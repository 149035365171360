<script>
export default {
};
</script>

<template>
  <div class="_horizontalRuler"></div>
</template>

<style>
._horizontalRuler {
  max-width: 800px;
  padding: 0.5rem 0;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  margin: 1rem 2.25rem;

  @media (--tabletAndDesktop) {
    margin: 0.5rem auto 1rem auto;
  }
}
</style>
